import React, { useState, useEffect, Fragment } from 'react'
import { addDays, format, eachDayOfInterval } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import {
  Button,
  LegacyCard,
  Layout,
  Popover,
  ChoiceList,
  LegacyStack,
  Icon,
  ButtonGroup,
  Badge,
  Text,
  Page,
  Tooltip,
} from "@shopify/polaris";
import { CalendarIcon, RefreshIcon, InfoIcon } from "@shopify/polaris-icons";
import update from 'immutability-helper'
import formatCurrency from '../../../helpers/formatCurrency'
import Insights from './insights';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { PolarisVizProvider, LineChart } from '@shopify/polaris-viz'
import "@shopify/polaris-viz/build/esm/styles.css";
import styled from "styled-components"
import OnboardingForNew from '../../../components/onBoardingForNew';
import Proposal from './proposal';
import calAnalyticsPerOffer from '../../../helpers/calAnalyticsPerOffer';
const Title = styled.div`
  .Polaris-Heading {
    font-size: 1.8rem;
  }
  padding: 25px 0;
`
const TotalTitle = (props) => (
  <Fragment>
    <h1 
      {...props} 
      style={{ 
        fontSize: '12px',
        display: 'inline-block',
        fontWeight: 500,
      }}
    >
      {props.title}
    </h1>
  </Fragment>
)
const Total = (props) => <h2 {...props} style={update(styles.total, { $merge: props.style || {fontSize: '20px', fontWeight: 600}})} >{props.children}</h2>


const colors = {
  impressions: (a = 1) => `rgba(255, 234, 138, ${a})`,
  conversions: (a = 1) => `rgba(54, 162, 235, ${a})`,
  revenue: (a = 1) => `rgba(75, 192, 192, ${a})`,
}
const styles = {
  total: {
    fontSize: '2.5em',
    margin: '12px 0',
  }
}

const filterOptions = (rule) => {
  return {
    label: rule.name && rule.name !== "" ? rule.name : (rule.id ? `${rule.id.substring(0, 8)}... (Unnamed)` : "Unnamed"),
    value: rule.id
  }
  
}

const Chart = (props) => {

  const { host, rawAnalytics = [], rules = [], locale, currency, setChartDateRange, refreshLoading, refresh, chartDateRange} = props;
  const [analytics, setAnalytics] = useState(rawAnalytics.map(datapoint => { return {
    conversions: datapoint.orderCreate,
    impressions: datapoint.impressions,
    revenue: datapoint.orderCreate,
    dateString: datapoint.dateString
  }}))
  const newAnalytics = calAnalyticsPerOffer(rawAnalytics);
  const [state, setState] = useState({
    showDatePicker: false,
    showOfferChoice: false,
    dateRangeLabel: 'Last Week',
  })
  const [date, setDate] = useState([])
  const [impressionsData, setImpressionsData] = useState([{
    name: "",
    data: []
  }])
  const [conversionsData, setConversionsData] = useState([{
    name: "",
    data: []
  }])
  const [revenueData, setRevenueData] = useState([{
    name: "",
    data: []
  }])
  const [conversionRate, setConversionRate] = useState("0.00%")
  const [totalImpression, setTotalImpression] = useState(0);
  const [aua, setAua] = useState(0)
  const [aov, setAov] = useState(0)
  const [selected, setSelected] = useState([])
  const [dateRangeLoading, setDateRangeLoading] = useState(false)
  const handleChange = (value) => {
      updateChart(datePickerState[0].startDate, datePickerState[0].endDate, value);
    setSelected(value)
  }
  const toggleDatePicker = () => setState(update(state, { showDatePicker: { $set: !state.showDatePicker }}))
  const toggleOfferChoice = () => setState(update(state, { showOfferChoice: { $set: !state.showOfferChoice }}))
  // map: dateString -> data
  const analyticsData = analytics.reduce((memo, data) => {
    memo[data.dateString] = data
    return memo
  }, {})
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: analytics && analytics.length ? new Date(analytics[0].dateString + 'T00:00:00'): addDays(new Date(), -7),
      endDate: analytics && analytics.length ? new Date(analytics[analytics.length-1].dateString + 'T00:00:00'): new Date(),
      key: 'selection'
    }
  ])
  let preparedRules = rules.map((item) => ({
    ...item,
    analytics: {
      ...newAnalytics[item.id],
      conversion: newAnalytics[item.id]?.conversion || "0.00%",
      impressions: newAnalytics[item.id]?.impressions || 0,
      revenue: newAnalytics[item.id]?.revenue || 0,
    },
  }));
  
  let cartRevenue = preparedRules.filter((item) => item.offerType == "Cart").reduce((sum, rule) => {
    return sum + (rule.analytics.revenue || 0)
  }, 0)
  let cartActive = preparedRules.filter((item) => (item.offerType == "Cart" && item.enabled)).length > 0;

  let checkoutRevenue = preparedRules.filter((item) => item.offerType == "Checkout").reduce((sum, rule) => {
    return sum + (rule.analytics.revenue || 0)
  }, 0)
  
  let checkoutActive = preparedRules.filter((item) => (item.offerType == "Checkout" && item.enabled)).length > 0;

  let ppRevenue = preparedRules.filter((item) => item.offerType == "Post Purchase").reduce((sum, rule) => {
    return sum + (rule.analytics.revenue || 0)
  }, 0)
  
  let ppActive = preparedRules.filter((item) => (item.offerType == "Post Purchase" && item.enabled)).length > 0;

  let productRevenue = preparedRules.filter((item) => item.offerType == "Product").reduce((sum, rule) => {
    return sum + (rule.analytics.revenue || 0)
  }, 0)
  
  let productActive = preparedRules.filter((item) => (item.offerType == "Product" && item.enabled)).length > 0;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const updateChart = (startDate, endDate, rules, analytics) => {
    const intervalDays = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
    const labels = [];
    const impressions = [];
    const conversions = [];
    const netRevenue = [];
    let aovConversionCount = 0, aovRevenue = 0
    for (let date of intervalDays) {
      const dateString = format(date, "yyyy-MM-dd"); // YYYY-MM-DD
      const dateLabel = format(date, 'MMM d')
      labels.push(dateLabel);
      let data = analyticsData[dateString];
      if(analytics){
        data = analytics[dateString];
      }
      // Guard for in case data for some days is missing, which normally shouldn't happen
      if (data) { 
        let impressionPoint = {value: 0, key: dateLabel}, conversionPoint = {value: 0, key: dateLabel}, netRevenuePoint = {value: 0, key: dateLabel}
        if(data.conversions.totalRevenue){
          aovConversionCount += data.conversions.totalCount;
          aovRevenue += data.conversions.totalRevenue;
        }
        if(rules.length >0){
          rules.map( ruleId => {
            if(data.impressions.hasOwnProperty(ruleId)){
              impressionPoint.value += data.impressions[ruleId].totalCount;
            }
            if(data.conversions.hasOwnProperty(ruleId)){
              conversionPoint.value += data.conversions[ruleId].totalCount;
            }
            if(data.revenue.hasOwnProperty(ruleId)){
              netRevenuePoint.value += data.revenue[ruleId].totalUpsoldRevenue;
            }
          })
        }else{
          impressionPoint.value = data.impressions.totalCount;
          conversionPoint.value = data.conversions.totalCount;
          netRevenuePoint.value = data.revenue.totalUpsoldRevenue;
        }
        impressions.push(impressionPoint);
        conversions.push(conversionPoint);
        netRevenuePoint.value = Number(netRevenuePoint.value).toFixed(2)
        netRevenue.push(netRevenuePoint);
        
      }else{
        impressions.push({value: 0, key: dateLabel});
        conversions.push({value: 0, key: dateLabel});
        netRevenue.push({value: 0, key: dateLabel});
      }
      
    }
    const totalOrderCreate = conversions.reduce((memo, item) => {
      return memo + Number(item.value)
    }, 0)

    const totalImpressions = impressions.reduce((memo, item) => {
      return memo + Number(item.value)
    }, 0)

    const totalNetRevenue = netRevenue.reduce((memo, item) => {
      return memo + Number(item.value)  
    }, 0)
    setTotalImpression(totalImpressions);
    setConversionRate(totalImpressions ? ((totalOrderCreate/totalImpressions)*100).toFixed(2).toString()+"%" : "0.00%")
    setAua(totalOrderCreate ? totalNetRevenue/totalOrderCreate : 0)
    setAov(aovConversionCount ? aovRevenue/aovConversionCount : 0)
    setImpressionsData(update(impressionsData, {[0]:{
      name: {$set: `${format(startDate, 'M/d')} - ${format(endDate, 'M/d')}` },
      data: {$set: impressions }
    }}));
    setConversionsData(update(conversionsData, {[0]:{
      name: {$set: `${format(startDate, 'M/d')} - ${format(endDate, 'M/d')}` },
      data: {$set: conversions }
    }}));
    setRevenueData(update(revenueData, {[0]:{
      name: {$set: `${format(startDate, 'M/d')} - ${format(endDate, 'M/d')}` },
      data: {$set: netRevenue }
    }}));
    setDate([startDate, endDate])
  }
  const setDateRange = async (startDate, endDate) => {
    // Each day between startDate and endDate inclusive
      updateChart(startDate, endDate, selected);
      setState(update(state, {
        dateRangeLabel: { $set: `${format(startDate, 'M/d')} to ${format(endDate, 'M/d')}` }
      }));
  }
  const applyDateRange = () => {
    setDateRangeLoading(true)
    const { startDate, endDate } = datePickerState[0]
    setChartDateRange({startDate, endDate})
    localStorage.setItem('upsellplus_dateRange', JSON.stringify({startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd")}))
  }
  
  
  useEffect(() => {
    let isMounted = true; 
    if(!analytics.length) {
      return
    }
    const firstDateString = analytics[0].dateString
    const lastDateString = analytics[analytics.length-1].dateString
    if (isMounted) {
    setDateRange(
      new Date(`${firstDateString}T00:00:00`),
      new Date(`${lastDateString}T00:00:00`),
    )
    }
    return () => { isMounted = false };
  }, [analytics])

  if ((rules && rules.length) || localStorage.getItem('close_onboarding')) {
    return (
      <Page
            title="Dashboard"
            fullWidth
          >
      <Layout>
        
        <Layout.Section>
          <Layout>
            <Layout.Section>
            <div style={{display:'flex', justifyContent:"space-between"}}>
            <LegacyStack>
            <Popover
              active={state.showDatePicker}
              activator={
                <Button onClick={toggleDatePicker}>
                <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
                <Icon
                  source={CalendarIcon}
                  tone="base" style={{display:'inline-block'}}></Icon>
                    <span>{state.dateRangeLabel}</span>
                </div>
              </Button>
              }
              onClose={toggleDatePicker}
              ariaHaspopup={false}
              fluidContent={true}
              >
              <div>
              <div style={{ borderBottom: '1px solid #eff2f7'}}>
              <DateRangePicker
                onChange={item => {
                  
                  setDatePickerState([item.selection])
                  
                }} 
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={datePickerState}
                direction="horizontal"
                months={2}
                dateDisplayFormat={"yyyy-MM-dd"}
                editableDateInputs={true}
              />
              </div>
              <div style={{float: 'right', padding: '15px'}}>
                <ButtonGroup>
                  <Button onClick={toggleDatePicker}>Cancel</Button>
                  <Button

                    onClick={() => applyDateRange()}
                    loading={dateRangeLoading}
                    variant="primary">Apply</Button>
                </ButtonGroup>
              </div>
              </div>
              </Popover>
              <Popover
              active={state.showOfferChoice}
              activator={
                <Button onClick={toggleOfferChoice}>
                  <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
                  <Icon
                    source={CalendarIcon}
                    tone="base" style={{display:'inline-block'}}></Icon>
                    {selected.length === 0 ?
                      <span>All offers</span>
                      :
                      <span>Offers ({selected.length})</span>
                    }
                  </div>
                </Button>
              }
              onClose={toggleOfferChoice}
              ariaHaspopup={false}
              fluidContent={true}
              >
                <div style={{padding: '10px'}}>
                  <div style={{paddingBottom: '10px'}}>
                    <Text variant="headingSm" as="h3">All offers</Text>
                  </div>
                  {rules.filter( rule => ("Checkout" === rule.offerType || !rule.offerType) && !rule.archived).length > 0 &&
                  <ChoiceList
                    allowMultiple
                    title="CHECKOUT"
                    choices={
                      rules.filter( rule => ("Checkout" === rule.offerType || !rule.offerType) && !rule.archived).map( rule => {return filterOptions(rule)})
                      }
                    selected={selected}
                    onChange={handleChange}
                  />
                  }
                  {rules.filter( rule => "Post Purchase" === rule.offerType && !rule.archived).length > 0 &&
                  <ChoiceList
                    allowMultiple
                    title="POST PURCHASE"
                    choices={
                      rules.filter( rule => "Post Purchase" === rule.offerType && !rule.archived).map( rule => {return filterOptions(rule)})
                      }
                    selected={selected}
                    onChange={handleChange}
                  />
                  }
                  {rules.filter( rule => "Cart" === rule.offerType && !rule.archived).length > 0 &&
                  <ChoiceList
                    allowMultiple
                    title="CART"
                    choices={
                      rules.filter( rule => "Cart" === rule.offerType && !rule.archived).map( rule => {return filterOptions(rule)})
                      }
                    selected={selected}
                    onChange={handleChange}
                  />
                  }
                  {rules.filter( rule => "Product" === rule.offerType && !rule.archived).length > 0 &&
                  <ChoiceList
                    allowMultiple
                    title="PRODUCT"
                    choices={
                      rules.filter( rule => "Product" === rule.offerType && !rule.archived).map( rule => {return filterOptions(rule)})
                      }
                    selected={selected}
                    onChange={handleChange}
                  />
                  }
                </div>
              </Popover>
              <Button onClick={() => refresh()} loading={refreshLoading}>
                  <div style={{display:'flex', alignItems: 'center',justifyContent: 'center', gap: '5px'}}>
                    <Icon
                      source={RefreshIcon}
                      tone="base" style={{display:'inline-block'}}>
                    </Icon>
                    Refresh
                  </div>
                </Button>
              </LegacyStack>
              {/*
              <Proposal token={props.token} shop={props.shop} host={host}/>
              */}
              </div>
            </Layout.Section>
            
          </Layout>
        </Layout.Section>
        <Layout.Section>
          <Layout>
          <Layout.Section variant="oneThird">
                    <LegacyCard 
                      title={
                      <LegacyStack>
                        <LegacyStack.Item fill>
                          <TotalTitle title="Checkout page" />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <span>{formatCurrency(checkoutRevenue,locale,currency)}</span>
                        </LegacyStack.Item>
                      </LegacyStack>
                    } 
                    >
                      <LegacyCard.Section>
                        <Badge tone={checkoutActive ? "success" : "warning"}>{checkoutActive ? "Active" : "Inactive"}</Badge>
                      </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>
                <Layout.Section variant="oneThird">
                    <LegacyCard 
                      title={
                      <LegacyStack>
                        <LegacyStack.Item fill>
                          <TotalTitle title="Post-purchase page" />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <span>{formatCurrency(ppRevenue,locale, currency)}</span>
                        </LegacyStack.Item>
                      </LegacyStack>
                    }>
                      <LegacyCard.Section>
                        <Badge tone={ppActive ? "success" : "warning"}>{ppActive ? "Active" : "Inactive"}</Badge>
                      </LegacyCard.Section>
                    </LegacyCard>
                </Layout.Section>              
              <Layout.Section variant="oneThird">
                <LegacyCard 
                  title={
                    <>
                      <LegacyStack>
                        <LegacyStack.Item fill>
                          <TotalTitle title="Cart" />
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <span>{formatCurrency(cartRevenue,locale, currency)}</span>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </>
                  } 
                >
                  <LegacyCard.Section>
                    <Badge tone={cartActive ? "success" : "warning"}>{cartActive ? "Active" : "Inactive"}</Badge>
                  </LegacyCard.Section>
                </LegacyCard>
              </Layout.Section>
                     
              <Layout.Section variant="oneThird">
                  <LegacyCard 
                    title={
                    <LegacyStack>
                      <LegacyStack.Item fill>
                        <TotalTitle title="Product page" />
                      </LegacyStack.Item>
                      <LegacyStack.Item>
                        <span>{formatCurrency(productRevenue,locale, currency)}</span>
                      </LegacyStack.Item>
                    </LegacyStack>
                  }>
                    <LegacyCard.Section>
                      <Badge tone={productActive ? "success" : "warning"}>{productActive ? "Active" : "Inactive"}</Badge>
                    </LegacyCard.Section>
                  </LegacyCard>
              </Layout.Section>                 
          </Layout>
        </Layout.Section>
        <Layout.Section>
        <PolarisVizProvider 
          themes={{
              Light: {
                chartContainer: {
                  borderRadius: '0px',
                  padding: '0px',
                  backgroundColor: 'transparent'
                },
                xAxis: {
                  labelColor: 'rgb(112, 112, 123)',
                },
                yAxis: {
                  labelColor: 'rgb(112, 112, 123)',
                },
                grid: {
                  color: '#eeeeef',
                  horizontalOverflow: false,
                  verticalOverflow: false,
                },
                line: {
                  pointStroke: '#0A97D5',
                },
                legend: {
                  backgroundColor: 'rgb(246, 246, 247)',
                  labelColor: 'rgb(112, 112, 123)',
                },
                seriesColors: {
                  single: '#0A97D5',
                },
              }
          }}
        >
          <Layout>
            <Layout.Section variant="oneThird">
              <LegacyCard 
                title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <TotalTitle title="Total revenue" color={colors.revenue()} />
                      <Total>{formatCurrency(revenueData[0].data.reduce((memo, d) => memo + Number(d.value), 0),locale, currency)}</Total>
                    </LegacyStack.Item>
                  </LegacyStack>
                } 
                sectioned
              >
                
                <LineChart data={revenueData} theme="Light" />
              
              </LegacyCard>
            </Layout.Section>
            <Layout.Section variant="oneThird">
                <LegacyCard 
                  title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <TotalTitle title="Total impressions" color={colors.impressions()} />
                      <Total>{numberWithCommas(Math.floor(impressionsData[0].data.reduce((memo, d) => memo + Number(d.value), 0)))}</Total>
                    </LegacyStack.Item>
                   
                  </LegacyStack>
                } 
                  sectioned
                >
                  
                  <LineChart data={impressionsData} theme="Light" />
              
                </LegacyCard>
            </Layout.Section>
            <Layout.Section variant="oneThird">
                <LegacyCard 
                  title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <TotalTitle title="Total conversions" color={colors.conversions()} />
                      <Total>{numberWithCommas(Math.floor(conversionsData[0].data.reduce((memo, d) => memo + Number(d.value), 0)))}</Total>
                    </LegacyStack.Item>
                   
                  </LegacyStack>
                } 
                  sectioned
                >
                 
                  <LineChart data={conversionsData} theme="Light" />
              
                </LegacyCard>
            </Layout.Section>
            <Layout.Section variant="oneThird">
                <LegacyCard 
                  title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <TotalTitle title="Conversion (%)" color={colors.conversions()} />
                      <Total>{conversionRate}</Total>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <span>{state.dateRangeLabel}</span>
                    </LegacyStack.Item>
                  </LegacyStack>
                } 
                  sectioned
                >
                </LegacyCard>
                {aov > 0 ?
                <LegacyCard 
                  title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <LegacyStack spacing="extraTight" alignment="center">
                        <TotalTitle title="AOV of orders containing an upsell" color={colors.conversions()} /> <Tooltip content="This reflects the overall AOV of all orders containing an upsell" dismissOnMouseOut><Icon source={InfoIcon} tone="subdued"/></Tooltip>
                      </LegacyStack>
                      <Total>{formatCurrency(aov, locale, currency)}</Total>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <span>{state.dateRangeLabel}</span>
                    </LegacyStack.Item>
                  </LegacyStack>
                } 
                  sectioned
                >
                </LegacyCard>
                :
                false
                }
                <LegacyCard 
                  title={
                  <LegacyStack>
                    <LegacyStack.Item fill>
                      <TotalTitle title="Average upsell amount" color={colors.conversions()} />
                      <Total>{formatCurrency(aua, locale, currency)}</Total>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                      <span>{state.dateRangeLabel}</span>
                    </LegacyStack.Item>
                  </LegacyStack>
                } 
                  sectioned
                >
                </LegacyCard>
                
            </Layout.Section>
          
          </Layout>
          </PolarisVizProvider>
          
        </Layout.Section>
        <Insights TotalTitle={TotalTitle} date={date} chartDateRange={chartDateRange} rules={rules} state={state} formatCurrency={formatCurrency} locale={locale} currency={currency} token={props.token} shop={props.shop} host={host} analytics={rawAnalytics} totalImpression={totalImpression} />
        
      </Layout>
      </Page>
    );
  }
  else {
    return (
      <Page title="Onboarding">
        <OnboardingForNew rules={rules} initStep={1}/>
      </Page>
    );
  }
}

export default Chart